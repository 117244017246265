<!-- =========================================================================================
  File Name: .vue
  Description:
========================================================================================== -->

<template>
  <div id="booking-page">
    <div class="vx-row" v-show="!appointmentConfirmed">
      <div class="vx-col lg:w-1/1 w-full">
        <vx-card>
          <div>
            <p>FICHE D'INSCRIPTION MATÉRNITÉ EN LIGNE</p>
             <!-- p>Sumary of your appointment at ABC Clinic {{slot_id}} - {{ fullDate }} - {{fullDateToArray}}- {{day_no}} - {{pkg_id}}</p -->
          </div>
        </vx-card>
      </div>
    </div>
    <br />
Add pregnancyDueDate


    <div class="vx-row" v-show="appointmentConfirmed">
      <!--div class="vx-col lg:w-1/1 w-full">
        <vx-card>
          <div>
            <p> Your appointment is confirmed {{appointmentId}} </p>
          </div>
        </vx-card>
      </div-->
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <h1 class="sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color">Your appointment is confirmed {{appointmentId}}</h1>
            <p class="sm:mx-0 mx-4 mb-6 d-theme-text-inverse">Thank you, your appointment request has been successfully sent. Requests typically are confirmed within 24 hours.</p>
            <vs-button size="large" to="/">Back to Home</vs-button>
        </div>
    </div>

  <br />

    <div class="vx-row" v-show="!appointmentConfirmed">
      <div class="vx-col lg:w-3/3 w-full">
        <vx-card
          title="Personne hospitalisée"
          subtitle="Be sure to check &quot;Deliver to this address&quot; when you have finished"
          class="mb-base">
          <form data-vv-scope="add-new-address">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
              <h5>Patient Type</h5>
                <div class="demo-alignment mb-5">
                  <vs-radio v-model="patientType" vs-value="existing">Exsiting</vs-radio>
                  <vs-radio v-model="patientType" vs-value="new">New</vs-radio>
                </div>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
              <h5>Gender</h5>
                <div class="demo-alignment mb-5">
                  <vs-radio v-model="gender" vs-value="female">Female</vs-radio>
                  <vs-radio v-model="gender" vs-value="male">Male</vs-radio>
                </div>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="firstName"
                  label="firstName"
                  v-model="firstName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.firstName')"
                  class="text-danger"
                >{{ errors.first('add-new-address.firstName') }}</span>
              </div>

              <div class="vx-col sm:w-1/3 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="lastName"
                  label="lastName"
                  v-model="lastName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.lastName')"
                  class="text-danger"
                >{{ errors.first('add-new-address.lastName') }}</span>
              </div>

              <div class="vx-col sm:w-1/3 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="fullName"
                  label="fullName"
                  v-model="fullName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.fullName')"
                  class="text-danger"
                >{{ errors.first('add-new-address.fullName') }}</span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input
                  v-validate="'required|email'"
                  data-vv-as="field"
                  name="email"
                  label="email"
                  v-model="email"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.email')"
                  class="text-danger"
                >{{ errors.first('add-new-address.email') }}</span>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
                <vs-input
                  v-validate="'required|digits:10'"
                  name="mobileNum"
                  label="Mobile Number:"
                  v-model="mobileNum"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.mobileNum')"
                  class="text-danger"
                >{{ errors.first('add-new-address.mobileNum') }}</span>
              </div>

      </div>




            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input
                  v-validate="'required'"
                  name="state"
                  label="State:"
                  v-model="state"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.state')"
                  class="text-danger"
                >{{ errors.first('add-new-address.state') }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/1 w-full">
                <div class="demo-alignment mb-5">
                  <p>J'ai été informé par l'Hôpital Privé Natecia Lyon du coût de chacune des prestations afférentes à mon séjour dans l'établissement, et en accepte toutes les conditions tarifaires - N'hésitez pas à faire deviser les honoraires de votre médecin." -->
<p/>
                  <vs-radio v-model="agreeTermsConditions" vs-value="existing">Conditions générales et approbation</vs-radio>
                </div>
              </div>
            </div>

            <vs-button  class="mt-6 ml-auto flex" @click="create_booking_details(pkg_id,day_no,slot_id)" >Complete your booking</vs-button>

          </form>
          <!-- p><pre>data: {{$data }}</pre></p -->
        </vx-card>
      </div>

    </div>

  </div>
</template>

<script>
import axios from "@/axios.js";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Datepicker from "vuejs-datepicker";
const HMinderEndPoint = "http://13.251.4.9:8090/";
//const HMinderEndPoint = "http://localhost:8090/";
export default {
  //el: '#bookingCHC',

  order: 1,
  components: {
    draggable,
    Prism,
    Datepicker,
    swiper,
    swiperSlide
  },
  mounted() {},
  data() {
    return {
      appointmentConfirmed: false,
      searchSummary:[],
      org_id: 1,
      patientId: 4,
      fullDate: "",
      fullDateToArray:"",
      appointmentId: "",
      description: "Package for Wellness",//thoothache
      firstName: "",
      lastName: "",
      dateOfBirth:"",
      fullName: "",
      email: "sofiane.hemici83@gmail.com",
      gender: "male",
      mobileNum: "+44 7500 99887",
      resourceType: "registration",
      patientType: "new",
      timezoneHour: "1",
      timezoneMinute: "30",
      medicalConsideration: "",
      additionalInformation: "",
      agreeTermsConditions: "",
      appointementReasons: [],
      appointementPreferredOptions: [],
      //appointementFollowupPreferredContactOptions: [{key:"AM", active: true, value:1},{key:"PM", active: true, value:2},{key:"ALL", active: true, value:3}],
      idNumber: "188FV23883",
      issuePlace: "",
      notes: "None",
           houseNum: "30",
      state: "Rhone Alpes",
      addressType:"",
      countryOptions: [
        { text: "France", value: "fr" },
        { text: "Italy", value: "it" },
        { text: "Germany", value: "de" }],
      addressTypeOptions: [
        { text: "Home", value: "home" },
        { text: "Work", value: "work" },
        { text: "Other", value: "other" }
      ],
      cityOptions: [
        { text: "Lyon", value: "lyon" },
        { text: "Rome", value: "rome" },
        { text: "Paris", value: "paris" },
        { text: "Munich", value: "munich" }
      ],
      source:"website",
      error_occured: false,
      error_msg: "",
      unavailability_msg:"Sorry, there is no available time. Please filled the form and we will call you back.",
    }
  },
  computed: {},
  methods: {

    create_booking_details(org_id) {
          const _this = this
  this.org_id = parseInt(org_id);
    this.org_id = parseInt(this.$route.params.org_id);
    this.startMinute = parseInt(this.$route.params.slotMinute);


      const date = new Date();
      const isoDateOffset = _this.$moment(date).format();
      console.log("isoDateOffset " + isoDateOffset);
//var fullDate =_this.$moment(this.day_no, "DDD").toArray();
      var postURL ="appointment/save";
      console.log("this.$route.params");
console.log(this.$route.params);
console.log("this.$route.params");
var  dataBook =
  {
    "allData": "string",
    "attr01": "string",
    "attr02": "string",
    "careTeam": {},
    "day": 9,
    "month": 8,
    "year": 2021,
    "hour": 15,
    "minute": 25,
    "intervalInSeconds": 900,
    "organization": {},
    "organizationId": 1,
    "packg": {},
    "packgId": 3,
    "patient": {},
    "patientId": 1,
    "resourceMobillizations": [{}],
    "second": 0,
    "startDay": 14,
    "startHour": 10,
    "startMinute": 0,
    "startMonth": 9,
    "startSecond": 0,
    "startYear": 2021,
    "status": "pending",
    "timezoneHour": 1,
    "timezoneMinute": 0,
    "type": "string"
  }



  console.log(dataBook);


      var config = {
        headers: { "Content-Type": "application/json" }
      };
  //console.log(this.$data);
  //this.$data
      //axios.post(HMinderEndPoint + postURL,"4a93ac04-1115-4634-8664-db27272f9323",config)
      axios.post(HMinderEndPoint + postURL, dataBook, config)
        .then(response => {
          console.log("postURL");
          console.log(response);
          //this.appointmentId = response.data.appointmentId;
          //console.log("appointmentId : " + this.appointmentId);
          this.appointmentConfirmed = true;
          //this.healthpackages = response.data;

        return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})

        });
    },
    fetch_booking_details(id) {
      console.log("Package ID : " + id);
      var config = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };

         axios.get(HMinderEndPoint + "packg/getById?id=" + id,config)
            .then((response) => {
              console.log("ServiceRequest");
              this.pkg_data = response.data;
              console.log("ServiceRequest size" + this.pkg_data.length);
              console.log(response.data);
      console.log("ServiceType");


            });
    },
    formSubmitted() {
      alert("Form submitted!" + this.$route.params.pkg_id);
    },
    submitForm() {
      console.log(this.$data);
    }
  },
  created() {
    const _this = this

    //this.create_booking_details(this.$route.params.pkg_id);

    this.org_id = this.packgId = this.$route.params.pkg_id;


    this.fullDate = _this.$moment(this.day_no, "DDD").format("DD-MM-YYYY");
//    _this.$moment("2010-10-20 4:30",       "YYYY-MM-DD HH:mm");   // parsed as 4:30 local time
//_this.$moment("2010-10-20 4:30 +0000", "YYYY-MM-DD HH:mm Z"); // parsed as 4:30 UTC
    this.fullDate =_this.$moment(this.day_no, "DDD").format("dddd DD MMMM YYYY, " + this.startHour +":" + this.startMinute +":ss A");
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

/*

<!--Obstétrique accouchement voie basse

Assuré social

Numéro de sécurité sociale
Adresse de votre caisse
Béneficiez-vous de la CMU ?
Téléchargez votre attestation de sécurité sociale ou de CMU

Avez-vous une mutuelle ?

Mutuelle
Adresse de votre mutuelle
Téléphone Mutuelle

Photocopie carte mutuelle

Mutuelle et Information

Pensez à prendre RDV pour la consultation pré-anesthésique (obligatoire) au cours de votre 6ème mois de grossesse (coordonnées : 04 37 90 33 03 ou 04 37 90 33 02).

Lors de votre PRÉ-ADMISSION (immédiatement après votre consultation pré-anesthésique), vous devrez vous munir d'un RIB et de vos devis praticiens (médecin et anesthésiste) signés pour bénéficier du paiement sécurisé à la fin de votre séjour. Sinon, seule une carte bancaire sera acceptée comme mode de paiement.

Lors de votre ADMISSION (en début de séjour), vous devrez demander une prise en charge à votre mutuelle.
Celle-ci pourra nous être transmise soit par mail : pec.mutuelle.natecia@noalys.com, soit par fax : 04 37 90 33 41. Mutuelle - Attention, aucune prise en charge ne pourra être prise en compte le jour de votre sortie.



"J'ai été informé par l'Hôpital Privé Natecia Lyon du coût de chacune des prestations afférentes à mon séjour dans l'établissement, et en accepte toutes les conditions tarifaires - N'hésitez pas à faire deviser les honoraires de votre médecin." -->
*/
</style>


